@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?&family=Source+Serif+Pro&display=swap);
body {
  margin: 0;
  font-size: 1rem;
  overflow: hidden;
  font-family: 'Source Sans Pro', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  opacity: 1;
  cursor: pointer;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem;
  outline: none;
  font-size: 0.8rem;

  background-color: rgb(47, 128, 237);
  color: white;
  border-radius: 4px;
  padding: 0.6rem 1rem;
  transition: all 0.2s;
}

button:hover {
  background-color: #0962d7;
}

.btn-bland {
  border: none;
  background-color: transparent;
}

.btn-bland:hover {
  border: none;
  background-color: transparent;
}

.btn-secondary {
  background-color: white;
  color: rgb(47, 128, 237);
  border: 1px solid rgb(47, 128, 237);
}

.btn-secondary:hover {
  background-color: hsla(214, 92%, 44%, 0.249);
}

.btn-disabled {
  opacity: 0.5;
}

* {
  box-sizing: border-box;
}

